* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

.thead-dark-custom th {
  color: #fff;
  background-color: #2c2e3e !important;
  border-color: #454d55;
}

.navbar-item-active{
  color: #514C90!important;
}

.background-card{
  background-color: transparent!important;
}


.aFazer{
  background-color: #f22d4e;
}

.agendado{
  background-color: #36a3f7
}

.atendendo{
  background-color: #34bfa3
}

.parado{
  background-color: #ffb822
}

.finalizado{
  background-color: #888
}

.cancelado{
  background-color: #333
}

.modal-bg{
  max-width: 850px!important;
}

.modal-footer-bg{
  background-color: #eff0f1 !important;
}

.print, .print-table{
  display: none;
}

.is-invalid-feedback{
  width: 100%!important;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545!important;
}

.icon-contestacao{
  color: #dc3545;
  font-size: 20px;
}

.span-btn{
  background-color: #e87c87 !important;
  border-color: #e87c87 !important;
}

.analise{
  background-color: #5a3faa;
}

.aceita{
  background-color: #1e7e34;
}

.recusada{
  background-color: #f22d4e;
}

.icon-alert-notificacao{
  color: #f5a30d;
  font-size: 13px;
}

.row-lista-tickets{
  margin-left: -6px !important;
}

.row-cobrancas{
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.text-center{
  text-align: center !important;
}

.div-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-loading{
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

@keyframes ldio-jz4kbaf040e {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-jz4kbaf040e div { box-sizing: border-box!important }
.ldio-jz4kbaf040e > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #514c90 transparent #514c90 transparent;
  animation: ldio-jz4kbaf040e 1s linear infinite;
}
.ldio-jz4kbaf040e > div:nth-child(2) { border-color: transparent }
.ldio-jz4kbaf040e > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-jz4kbaf040e > div:nth-child(2) div:before, .ldio-jz4kbaf040e > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #514c90;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #514c90;
}
.ldio-jz4kbaf040e > div:nth-child(2) div:after { 
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #514c90;
}
.loadingio-spinner-dual-ring-oingaqu2jlh {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-jz4kbaf040e {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.8);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-jz4kbaf040e div { box-sizing: content-box; }

.sidebar-short{
  min-width: 100px !important;
  width: 100px !important;
}

.contentSite-menu-compacto{
  margin-left: 100px !important;
}

.botao-drop button {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  position: relative;
  top: -4px;
}

.button-novo-ticket{
  min-width: 132px !important;
}

.button-novo-requisito{
  min-width: 140px !important;
}

.pendente{
  background-color: #f22d4e
}

.emAnalise{
  background-color: #ffb822
}