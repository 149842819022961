.title-dashboard {
  box-sizing: border-box;
  color: rgb(63, 64, 71);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.72px;
  float: left;
  width: 70%;
}

.subtitle-dashboard {
  float: left;
  box-sizing: border-box;
  color: rgb(150, 153, 162);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.5px;
  margin-top: 2.73px;
}

.value-dashboard{
  float: left;
  width: 30%;
  text-align: right;
  color: rgb(63, 64, 71);
}

.value-dashboard span {
  box-sizing: border-box; 
  direction: ltr;
  display: inline;
  font-family: Poppins;
  font-size: 23px;
  font-weight: 600;
  line-height: 29.25px;
  text-align: right;
  text-size-adjust: 100%;
  -webkit-box-direction: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dashboard-red{
  color: #EF3F5C;
}

.dashboard-blue{
  color: #514C90;
}

.dashboard-green{
  color: rgb(48, 154, 133)
}

.card-home {
  margin-top: 20px !important;
  background-color: white !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  border: none;
  background-color:white !important;
  min-height: 350px;
  max-height: 350px;
  margin: 0 auto 10px;
  padding: 15px 0px;
/* 
  WebkitBoxShadow: "0px 3px 15px rgba(0, 0, 0, 0.3)", */                    
}

.text-horas{
  font-size: 14px !important;
  color: rgb(150, 153, 162);
}

.descricao-mensagem-dashboard {
  display: block;
  float: left;
  width: 100%;
  padding: 15px 10px 10px 10px;
}