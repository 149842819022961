.box-notificacao {
  float: left;
  width: 100%;
  border: 1px solid #e8ecee !important;
}

.header-notificacao {
  background: #f6f8f9;
  border: 1px solid #e8ecee;
  padding: 10px 0 10px 20px;
  font-size: 13px;
  font-weight: 400;
}

.item-notificacao {
  width: 100%;
  float: left;
  position: relative;
  border-bottom: 1px solid #dde1e2;
}

.aha {
  width: 95%;
  float: left;
  padding: 20px 40px;
}

.tipo-notificacao {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #6f7782;
  display: -webkit-box;
}

.titulo-notificacao {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  color: #0518ad !important;
}

.link-notificacao{
  color: #6f7782;
  font-size: 13px;
  text-decoration: underline;
  margin-top: 25px;
  float: left;
  width: 100%;
  cursor: pointer;
}

.aha2{
  float: left;
  width: 5%;
  background-color: inherit !important;
  height: 100% !important;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  cursor: pointer;
}

.aha2:hover{
  background-color: #f6f8f9 !important;
  color: inherit !important;
  transition: background 150ms ease-in-out;
}

.arquivar-todos-notificacao{
  float: left;
  width: 100%;
  color: #2c2e3e!important;
  font-weight: bold;
  cursor: pointer;
  margin: 15px 0px;
  margin-bottom: 40px;
}

.nenhuma-notificacao{
  float: left;
    width: 100%;
    text-align: center;
    padding: 20px;
    font-weight: 500;
}