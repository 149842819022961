@import url(https://fonts.googleapis.com/css?family=Poppins:400,700);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

.thead-dark-custom th {
  color: #fff;
  background-color: #2c2e3e !important;
  border-color: #454d55;
}

.navbar-item-active{
  color: #514C90!important;
}

.background-card{
  background-color: transparent!important;
}


.aFazer{
  background-color: #f22d4e;
}

.agendado{
  background-color: #36a3f7
}

.atendendo{
  background-color: #34bfa3
}

.parado{
  background-color: #ffb822
}

.finalizado{
  background-color: #888
}

.cancelado{
  background-color: #333
}

.modal-bg{
  max-width: 850px!important;
}

.modal-footer-bg{
  background-color: #eff0f1 !important;
}

.print, .print-table{
  display: none;
}

.is-invalid-feedback{
  width: 100%!important;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545!important;
}

.icon-contestacao{
  color: #dc3545;
  font-size: 20px;
}

.span-btn{
  background-color: #e87c87 !important;
  border-color: #e87c87 !important;
}

.analise{
  background-color: #5a3faa;
}

.aceita{
  background-color: #1e7e34;
}

.recusada{
  background-color: #f22d4e;
}

.icon-alert-notificacao{
  color: #f5a30d;
  font-size: 13px;
}

.row-lista-tickets{
  margin-left: -6px !important;
}

.row-cobrancas{
  margin-right: -21px !important;
  margin-left: -21px !important;
}

.text-center{
  text-align: center !important;
}

.div-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-loading{
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

@-webkit-keyframes ldio-jz4kbaf040e {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

@keyframes ldio-jz4kbaf040e {
  0% { -webkit-transform: rotate(0); transform: rotate(0) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}
.ldio-jz4kbaf040e div { box-sizing: border-box!important }
.ldio-jz4kbaf040e > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #514c90 transparent #514c90 transparent;
  -webkit-animation: ldio-jz4kbaf040e 1s linear infinite;
          animation: ldio-jz4kbaf040e 1s linear infinite;
}
.ldio-jz4kbaf040e > div:nth-child(2) { border-color: transparent }
.ldio-jz4kbaf040e > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ldio-jz4kbaf040e > div:nth-child(2) div:before, .ldio-jz4kbaf040e > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #514c90;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #514c90;
}
.ldio-jz4kbaf040e > div:nth-child(2) div:after { 
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #514c90;
}
.loadingio-spinner-dual-ring-oingaqu2jlh {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-jz4kbaf040e {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(0.8);
          transform: translateZ(0) scale(0.8);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio-jz4kbaf040e div { box-sizing: content-box; }

.sidebar-short{
  min-width: 100px !important;
  width: 100px !important;
}

.contentSite-menu-compacto{
  margin-left: 100px !important;
}

.botao-drop button {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  box-shadow: none !important;
  position: relative;
  top: -4px;
}

.button-novo-ticket{
  min-width: 132px !important;
}

.button-novo-requisito{
  min-width: 140px !important;
}

.pendente{
  background-color: #f22d4e
}

.emAnalise{
  background-color: #ffb822
}
#login_container{
	width:100%;
	float:left;
	height:100%;
	background:#ccc;
	overflow:auto;
  background-size: cover;
}

.card-container.card {
    max-width: 370px;
    padding: 40px 20px;
}

.btn {
    font-weight: 700;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    cursor: default;
}

#captcha{
	width:100%;
	float:left;
	padding:10px 0px;
}

/*
 * Card component
 */
.card {
    background-color: transparent!important;
    /* just in case there no content*/
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* shadows and rounded borders */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0;
    min-height: 1em;
}

.reauth-email {
    display: block;
    color: #404040;
    line-height: 2;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
}

.form-signin #cnpj,
.form-signin #login,
.form-signin #password {
    direction: ltr;
    height: 50px;
    font-size: 16px;
}

.form-signin input[type=email],
.form-signin input[type=password],
.form-signin input[type=text],
.form-signin button {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
    box-sizing: border-box;
    background-color: transparent!important;
    border-radius: 50px;
    color: #fff;
    text-align: center;
}

.form-signin .form-control:focus {
    border-color: rgb(104, 145, 162);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
}

.btn.btn-signin {
    background-color: #f94;
/*    background-color: rgb(104, 145, 162);*/
    /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
    padding: 0px;
    font-weight: 700;
    font-size: 14px;
    height: 36px;
    border: none;
    -webkit-transition: all 0.218s;
    transition: all 0.218s;
		margin-top: 40px;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
/*    background-color: rgb(12, 97, 33);*/
    background-color: #fd831e;
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    opacity: 0.8;
}
.title-dashboard {
  box-sizing: border-box;
  color: rgb(63, 64, 71);
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.72px;
  float: left;
  width: 70%;
}

.subtitle-dashboard {
  float: left;
  box-sizing: border-box;
  color: rgb(150, 153, 162);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.5px;
  margin-top: 2.73px;
}

.value-dashboard{
  float: left;
  width: 30%;
  text-align: right;
  color: rgb(63, 64, 71);
}

.value-dashboard span {
  box-sizing: border-box; 
  direction: ltr;
  display: inline;
  font-family: Poppins;
  font-size: 23px;
  font-weight: 600;
  line-height: 29.25px;
  text-align: right;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dashboard-red{
  color: #EF3F5C;
}

.dashboard-blue{
  color: #514C90;
}

.dashboard-green{
  color: rgb(48, 154, 133)
}

.card-home {
  margin-top: 20px !important;
  background-color: white !important;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
  border: none;
  background-color:white !important;
  min-height: 350px;
  max-height: 350px;
  margin: 0 auto 10px;
  padding: 15px 0px;
/* 
  WebkitBoxShadow: "0px 3px 15px rgba(0, 0, 0, 0.3)", */                    
}

.text-horas{
  font-size: 14px !important;
  color: rgb(150, 153, 162);
}

.descricao-mensagem-dashboard {
  display: block;
  float: left;
  width: 100%;
  padding: 15px 10px 10px 10px;
}
.dashboard-red{
  color: #EF3F5C
}

.dashboard-blue{
  color: #514C90;
}

.dashboard-green{
  color:rgb(48, 154, 133)
}

.Novo{
  background-color: #888
}

.Dúvida{
  background-color: #d39e00
}

.Aprovado{
  background-color: #1e7e34
}

.Cancelado{
  background-color: #bd2130;
}

.btn-active-sucess{
  background-color: #1b652c;
}

.item-modal-descricao{
  float: left; 
  width:100%; 
  padding-top: 2px;
}
.group-tickets {
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 14px !important;
}

.font-tickets {
  font-size: 14px !important;
}

.item-apontamento {
  float: left;
  width: 100%;
  padding: 10px 0px;
}

.titulo-lista-apontamentos{
  font-size: 16px !important;
  font-weight: bold;
}

.item-apontamento-print{
  display: inline;
  float: left;
  padding: 0 30px 0 0;
}

.item-apontamento-print-descricao{
  float: left;
  width: 100%;
  padding-top: 10px;
}

.linha{
  float: left;
  width: 100% !important;
  min-height: 1px;
  max-height: 1px;
  background-color: #ccc !important;
  height: 1px;
}


.thead-print{
  border-top: 0 !important;
}
body{background-color: rgb(242, 243, 248)!important;}

.Example_navbar{
  font-family: 'Poppins', sans-serif!important;
  background-color:#FFF!important;
  padding: 0!important;
  margin-left: 230px;
}

.Example_navbar-brand{
  color: rgba(0,0,0,.9)!important;
  background-color: #282a3c!important;
  color: #d6d7e1!important;
  font-size: 25px!important;
}

.Example_item-menu{
  padding: 0px 20px;
  font-size: 25px;
}

.Example_item-menu i{
  cursor: pointer;
}

.Example_item-menu img{
  cursor: pointer;
  border-radius:50%;
  width: 50px;
}

.Example_navbar-itens-acesso{
  padding-left:23px!important;
}

.Example_nav-link {
  font-size: 15px;
  cursor: pointer;
  color: #2c2e3e!important;
}

.Example_nav-link:hover{
  text-decoration: none!important;
}

.Example_nav-link i{
  margin-right:10px;
}


.Example_nav-item{
  padding: 0 10px!important;
}

.icon-menu{
  cursor: pointer;
  color: #ad5beb;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #ad5beb), color-stop(50%, #c678db), color-stop(75%, #da6ea9), to(#e76e92));
  background: linear-gradient(180deg, #ad5beb 25%, #c678db 50%, #da6ea9 75%, #e76e92 100%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* SIDEBAR */
#Example_sidebar{
  background-color: #2c2e3e!important;
  min-width: 230px;
  width: 230px;
  height: 100%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: fixed;
  top:0;
}

/* BOOX HOME */
.Example_box{
  box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  background-color: #fff;
  width: 100%;
}

.Example_container-home{
  border-top: 1px solid #ddd;
}

.teste{
  width:230px;
  min-width: 230px; 
  color: rgba(0,0,0,.9)!important;
  background-color: #282a3c!important; 
  color: #d6d7e1!important;
  font-size: 25px!important;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;
  z-index: 9999;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.teste1{
  display: block;
  width: 25px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;  
  background: #cdcdcd;
  border-radius: 3px;  
  z-index: 1;  
  -webkit-transform-origin: 4px 0px;  
          transform-origin: 4px 0px;  
  -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);  
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.Example_box-header{
  font-size: 20px;
  padding: 20px 0px;
  font-weight: bold;
}

.Example_box-header span{
  padding: 30px;
}

.Example_box-content{
  padding: 30px;

}

#contentSite{
  margin-left: 230px;
  border-top: 1px solid #cecece;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tag{
  color: #444;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 25px;
  min-width: 88px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  padding: 0px 3px;
  border-radius: .75rem;
  letter-spacing: 0.6px;
  padding: 1px 10px;
  border-radius: 0;
  border-radius: .75rem;
  color: #fff;
}

.navbar-logo{
  height: 35px;
  width: 35px;
  position: relative;
  top: -2px;
}
.item-menu-li:hover{
  background-color: #282935!important;
}

.item-menu-li-active{
  background-color: #282935!important;
}
.item-detalhe-ticket{
  padding: 3px 0px;
}

.item-detalhe-ticket-span{
  min-width: 370px!important;
  max-width: 370px !important;
  width: 370px !important;
  display: inline-block;
}

.item-detalhe-ticket-contestacao{
  padding: 10px 0px;
}
.box-notificacao {
  float: left;
  width: 100%;
  border: 1px solid #e8ecee !important;
}

.header-notificacao {
  background: #f6f8f9;
  border: 1px solid #e8ecee;
  padding: 10px 0 10px 20px;
  font-size: 13px;
  font-weight: 400;
}

.item-notificacao {
  width: 100%;
  float: left;
  position: relative;
  border-bottom: 1px solid #dde1e2;
}

.aha {
  width: 95%;
  float: left;
  padding: 20px 40px;
}

.tipo-notificacao {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #6f7782;
  display: -webkit-box;
}

.titulo-notificacao {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  color: #0518ad !important;
}

.link-notificacao{
  color: #6f7782;
  font-size: 13px;
  text-decoration: underline;
  margin-top: 25px;
  float: left;
  width: 100%;
  cursor: pointer;
}

.aha2{
  float: left;
  width: 5%;
  background-color: inherit !important;
  height: 100% !important;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  cursor: pointer;
}

.aha2:hover{
  background-color: #f6f8f9 !important;
  color: inherit !important;
  -webkit-transition: background 150ms ease-in-out;
  transition: background 150ms ease-in-out;
}

.arquivar-todos-notificacao{
  float: left;
  width: 100%;
  color: #2c2e3e!important;
  font-weight: bold;
  cursor: pointer;
  margin: 15px 0px;
  margin-bottom: 40px;
}

.nenhuma-notificacao{
  float: left;
    width: 100%;
    text-align: center;
    padding: 20px;
    font-weight: 500;
}
body{
  background-color: #FFF!important;
}

.label-form{
  margin-bottom: 0.1rem!important;
}
.item-detalhe-ticket{
  padding: 3px 0px;
}

.item-detalhe-ticket-span{
  min-width: 370px!important;
  max-width: 370px !important;
  width: 370px !important;
  display: inline-block;
}

.item-detalhe-ticket-contestacao{
  padding: 10px 0px;
}
