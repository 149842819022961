.group-tickets {
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-size: 14px !important;
}

.font-tickets {
  font-size: 14px !important;
}

.item-apontamento {
  float: left;
  width: 100%;
  padding: 10px 0px;
}

.titulo-lista-apontamentos{
  font-size: 16px !important;
  font-weight: bold;
}

.item-apontamento-print{
  display: inline;
  float: left;
  padding: 0 30px 0 0;
}

.item-apontamento-print-descricao{
  float: left;
  width: 100%;
  padding-top: 10px;
}

.linha{
  float: left;
  width: 100% !important;
  min-height: 1px;
  max-height: 1px;
  background-color: #ccc !important;
  height: 1px;
}


.thead-print{
  border-top: 0 !important;
}