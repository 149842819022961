.item-detalhe-ticket{
  padding: 3px 0px;
}

.item-detalhe-ticket-span{
  min-width: 370px!important;
  max-width: 370px !important;
  width: 370px !important;
  display: inline-block;
}

.item-detalhe-ticket-contestacao{
  padding: 10px 0px;
}