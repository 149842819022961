.dashboard-red{
  color: #EF3F5C
}

.dashboard-blue{
  color: #514C90;
}

.dashboard-green{
  color:rgb(48, 154, 133)
}

.Novo{
  background-color: #888
}

.Dúvida{
  background-color: #d39e00
}

.Aprovado{
  background-color: #1e7e34
}

.Cancelado{
  background-color: #bd2130;
}

.btn-active-sucess{
  background-color: #1b652c;
}

.item-modal-descricao{
  float: left; 
  width:100%; 
  padding-top: 2px;
}