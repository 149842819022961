@import url('https://fonts.googleapis.com/css?family=Poppins:400,700');

body{background-color: rgb(242, 243, 248)!important;}

.Example_navbar{
  font-family: 'Poppins', sans-serif!important;
  background-color:#FFF!important;
  padding: 0!important;
  margin-left: 230px;
}

.Example_navbar-brand{
  color: rgba(0,0,0,.9)!important;
  background-color: #282a3c!important;
  color: #d6d7e1!important;
  font-size: 25px!important;
}

.Example_item-menu{
  padding: 0px 20px;
  font-size: 25px;
}

.Example_item-menu i{
  cursor: pointer;
}

.Example_item-menu img{
  cursor: pointer;
  border-radius:50%;
  width: 50px;
}

.Example_navbar-itens-acesso{
  padding-left:23px!important;
}

.Example_nav-link {
  font-size: 15px;
  cursor: pointer;
  color: #2c2e3e!important;
}

.Example_nav-link:hover{
  text-decoration: none!important;
}

.Example_nav-link i{
  margin-right:10px;
}


.Example_nav-item{
  padding: 0 10px!important;
}

.icon-menu{
  cursor: pointer;
  color: #ad5beb;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #ad5beb), color-stop(50%, #c678db), color-stop(75%, #da6ea9), to(#e76e92));
  background: linear-gradient(180deg, #ad5beb 25%, #c678db 50%, #da6ea9 75%, #e76e92 100%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* SIDEBAR */
#Example_sidebar{
  background-color: #2c2e3e!important;
  min-width: 230px;
  width: 230px;
  height: 100%;
  transition: all 0.5s ease;
  position: fixed;
  top:0;
}

/* BOOX HOME */
.Example_box{
  -webkit-box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
  background-color: #fff;
  width: 100%;
}

.Example_container-home{
  border-top: 1px solid #ddd;
}

.teste{
  width:230px;
  min-width: 230px; 
  color: rgba(0,0,0,.9)!important;
  background-color: #282a3c!important; 
  color: #d6d7e1!important;
  font-size: 25px!important;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;
  z-index: 9999;
  transition: all 0.5s ease;
}

.teste1{
  display: block;
  width: 25px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;  
  background: #cdcdcd;
  border-radius: 3px;  
  z-index: 1;  
  transform-origin: 4px 0px;  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.Example_box-header{
  font-size: 20px;
  padding: 20px 0px;
  font-weight: bold;
}

.Example_box-header span{
  padding: 30px;
}

.Example_box-content{
  padding: 30px;

}

#contentSite{
  margin-left: 230px;
  border-top: 1px solid #cecece;
  transition: all 0.5s ease;
}

.tag{
  color: #444;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 25px;
  min-width: 88px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  padding: 0px 3px;
  border-radius: .75rem;
  letter-spacing: 0.6px;
  padding: 1px 10px;
  border-radius: 0;
  border-radius: .75rem;
  color: #fff;
}

.navbar-logo{
  height: 35px;
  width: 35px;
  position: relative;
  top: -2px;
}